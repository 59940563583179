<template>
  <Layout tituloPagina="Sistema | Roles de usuarios">
    <div class="row">
      <div class="col-lg-5">
        <cmp-secciones vista="roles"></cmp-secciones>
      </div>
    </div>

    <div class="row">
      <div class="col-md-7">
        <div class="row">
          <div class="col-sm-10 col-9 mb-3">
            <filtrador
              v-on:dato-actualizado="
                dato = $event,
                refrescarRoles()
              "
              :datoini="dato"
              placeholder="Dato del rol"
            ></filtrador>
          </div>
          <div class="col-sm-2 col-3 text-right">
            <router-link
              :to="{ name: 'nuevoRolUsuarios' }"
              class="btn btn-success"
              v-show="tienePermiso('nuevoRolUsuarios', 'vista')"
            >
              <span class="d-none d-sm-block">
                <i class="fa fa-plus"></i>
                Nuevo
              </span>
              <span class="d-block d-sm-none">
                <i class="fa fa-plus"></i>
              </span>
            </router-link>
          </div>
        </div>

        <br />

        <div class="card">
          <div class="card-body" style="min-height: 475px;">
            <div class="table-responsive">
              <table class="table table-hover table-nowrap" id="tabla-roles-usuarios">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th style=" width: 120px">Permisos</th>
                    <th style=" width: 150px">Notificaciones</th>
                    <th style=" width: 200px"
                      v-show="
                        tienePermiso('edicionRolUsuarios', 'vista') ||
                        tienePermiso('eliminarRolUsuario', 'accion')
                      "
                    >Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="rol in roles" :key="rol.id">
                    <td class="fw-semibold">{{ rol.nombre }}</td>
                    <td>
                      <router-link :to="{ name: 'bloquesFunciones', query: {id_rol: rol.id} }"
                        class="btn btn-light btn-sm">
                        <i class="bx bx-list-ol"></i>
                        Permisos
                      </router-link>
                    </td>
                    <td>
                      <router-link :to="{ name: 'rolNotificaciones', query: {id_rol: rol.id} }"
                        class="btn btn-secondary btn-sm">
                        <i class="bx bxs-bell-ring"></i>
                        Notificaciones
                      </router-link>
                    </td>
                    <td v-show="
                      tienePermiso('sistema.edicionRolUsuarios', 'vista') ||
                      tienePermiso('sistema.eliminarRolUsuarios', 'accion')
                      ">
                      <button class="btn btn-soft-dark btn-sm"
                        title="Copiar rol de usuarios"
                        @click="copiar(rol)"
                        v-show="tienePermiso('sistema.copiarRolUsuarios', 'accion')">
                        <i class="bx bx-copy-alt"></i>
                      </button>
                      <router-link
                        :to="{
                          name: 'edicionRolUsuarios',
                          params: { id: rol.id }
                        }"
                        class="btn btn-sm"
                        :class="{'btn-warning': !rol.generico,'btn-info': rol.generico}"
                        v-show="tienePermiso('edicionRolUsuarios', 'vista')"
                      >
                        <i class="bx" :class="{'bx-edit': !rol.generico, 'bx bx-show-alt': rol.generico}"></i>
                        {{rol.generico?'Ver':'Editar'}}
                      </router-link>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="preguntaEliminarRol(rol)"
                        v-show="tienePermiso('sistema.eliminarRolUsuarios', 'accion') && !rol.generico"
                      >
                        <i class="bx bx-trash-alt"></i>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- PAGINADOR -->
        <div class="text-center">
          <paginador
            :pag="pagina"
            :totpag="ultimaPagina"
            :epp="porPagina"
            v-on:cargar-pagina="cargarPagina"
            v-on:epp-actualizado="
              porPagina = $event,
              refrescarRoles()
            "
          ></paginador>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import CmpSecciones from '../CmpSecciones.vue'
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import API from '@/API.js'

import RolUsuariosSrv from '@/services/RolUsuariosSrv.js'

import { todoGetters } from "@/state/helpers"

import Swal from 'sweetalert2'

export default {
  name: 'ListadoRolesUsuarios',
  components: {
    Layout,
    CmpSecciones,
    Filtrador,
    Paginador
  },
  data: function() {
    return {
      btnPagIni: 1,
      btnPagFin: 1,
      dato: '',
      pagina: 1,
      porPagina: parseInt(localStorage.getItem('argusblack.listadoRolesUsuarios.por_pagina') || 10),
      roles: [],
      ultimaPagina: 1
    }
  },
  created: function() {
    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.pagina) this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina) this.porPagina = parseInt(this.$route.query.por_pagina)
    
    // Cargas iniciales
    this.refrescarRoles()
  },
  computed:{
    ...todoGetters
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.refrescarRoles()
    },
    copiar: function(rol) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: `¿Deseas copiar el Rol (${rol.nombre})?`,
        html: `¿Está seguro de copiar el <strong>${rol.nombre}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self. copiarRol(rol)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    copiarRol: function(rol) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Nombre del nuevo rol",
        html: "Escribe el nombre del nuevo rol",
        input: "text",
        inputPlaceholder: "Escribe algo",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a",
        allowOutsideClick: false
      }).then(text => {
        if (text.value) {
          // Si tiene un nombre, se continúa
          RolUsuariosSrv.copiar(rol.id, text.value).then(response => {
            swal.fire("Copiado!", `Se clonó satisfactoriamente`, "success");
            self.refrescarRoles()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo copiar el rol'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    preguntaEliminarRol: function(rol) {
      var self = this
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Eliminar rol",
        html: '¿Está seguro que quiere eliminar el rol <strong>' + rol.nombre + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          RolUsuariosSrv.eliminar(rol.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente el rol", "success")
            self.refrescarRoles()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el rol'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    refrescarRoles: function() {
      var self = this

      iu.spinner.mostrar('#tabla-roles-usuarios')

      this.$router.replace({
        query: {
          dato: this.dato,
          pagina: this.pagina,
          por_pagina: this.porPagina,
          todos: this.todos
        }
      }).catch(error => {})

      let params = { 
        dato: self.dato,
        pagina: self.pagina,
        por_pagina: self.porPagina
      }
      
      RolUsuariosSrv.rolesJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoRolesUsuarios.por_pagina', self.porPagina)
        let res = response.data
        self.roles = res.roles
        self.ultimaPagina = res.ultima_pagina
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los roles'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-roles-usuarios')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-roles-usuarios')
  }
}
</script>

<style scoped>

</style>